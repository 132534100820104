module.exports = {
  therapistNotificationsEnabled:
    'Enabled therapist email notifications for this template',
  therapistNotificationsDisabled:
    'Disabled therapist email notifications for this template',
  notifyTherapists: 'Notify therapists via email',
  confirmRestore: 'Are you sure you want to restore the default message',
  redirectToSmsPayment: 'You will be redirected to the payment page',
  smsPacketBought:
    'We received confirmation of payment. SMS has been bought. You will receive an email with confirmation from Przelewy24.',
  packetPurchaseError:
    'Error while purchasing the packet. Please contact Przelewy24 or try again later.',
  smsPacketAwaitingPayment:
    'We are awaiting for your payment. You will receive an email with payment status from Przelewy24.',
  toVerifyMessage:
    'To verify your message just type your phone number and send it to yourself.',
  yourPhoneNumber: 'Your phone number',
  typePhoneNumber: 'Type your phone number…',
  close: '@:common.close',
  save: '@:common.save',
  ifYourMessageContain:
    'If your message contains the meeting date and time in this message it will be set to today’s date.',
  errorMessage: 'Please enter a valid phone number (9-18 characters)',
  sendSmsToPatient: 'Send SMS to patient',
  message: 'Message text',
  messageWillBeSentTo: 'Message will be sent to',
  sendTestMsg: 'Send test message',
  smsSend: 'Sms was sent successfully',
  send: 'Send',
  sms: 'SMS',
  configuration: 'Configuration',
  messages: 'Messages',
  history: 'History',
  smsUsage: 'SMS Usage',
  sendingTimes: 'Sending times',
  newMeeting: 'New meeting reminder',
  smsChangedTime: 'Send SMS when meeting time has changed',
  smsCancelledTime: 'Send SMS when meeting has been cancelled',
  buyMoreSms: 'Buy more SMS',
  smsInfo: 'Patients will receive automated reminder SMS messages',
  addSmsInfo:
    'Once turned on also your therapists will receive e-mail notification when a new patient is assigend to this therapists or a scheduled meeting has been cancelled.',
  smsSendingInfo:
    'When shall your patients receive automated reminder message?',
  smsNewMeetingInfo:
    'Send an automated meeting reminder everytime a new patient has a new meeting scheduled. Those meetings will be send in the moment that meeting has been scheduled. ',
  smsTimeChangeInfo:
    'Your patients can reply to SMS messages to confirm or cancel appointments',
  smsTimeCancelledInfo:
    'Your patient will receive a new SMS everytime his meeting will be cancelled.',
  haveSms: 'You have ',
  smsLeft: 'messages left until ',
  smsAdditional: 'messages from additional packages',
  smsUsed: 'SMS to be used.',
  total: 'Total:',
  smsUsageInfo:
    'When you buy additional SMS they last beyond your billing cycle until they are used-up. In the first place the system uses the regular SMS, once there is no more it will start using the extra ones.',
  sendSmsTo: 'Send it',
  sendSmsBefore: 'before the meeting at',
  oClock: "o'clock",
  smsPrior: 'prior to the meeting',
  saveSms: 'Save',
  smsPeriodBefore: 'Certain time period before the meeting',
  smsSpecificTime: 'At a specific time prior to the meeting',
  dialogSmsInfo:
    'You will be billed {startPrice} zł + VAT({vat} zł) = {sum} zł which will be added to your next invoice.',
  smsInvoiceWarn:
    'Before proceeding with payment, please make sure you have entered correct practice invoice data. If it will be empty you will receive invoice for physical person, not for company. To see this data go to Settings -> Practice -> Invoice data',
  purchaseSmsExplanation:
    'After you click Buy an email will be sent to us and we will contact you with an invoice and payment details.',
  buy: 'Buy',
  cancel: 'Cancel',
  reminderMessage: 'Reminder Message',
  reScheduledMeeting: 'Re-scheduled meeting',
  confirmationMessage: 'Confirmation message',
  meetingCancelled: 'Meeting cancelled',
  quickReplies: 'Quick replies',
  reScheduledInfo:
    'This message will be sent when a planned meeting has been rescheduled. ',
  confirmationInfo:
    'This confirmation message will be sent to your patient once his first meeting will be scheduled.',
  canceledInfo:
    'This message will be sent to your patient when his meeting will be cancelled.',
  sendSpecificMessages: 'Send specific messages for each branch',
  selectBranch: 'Select branch',
  yourMessage: 'Your message',
  charts: 'characters',
  messageCount: '{n} message | {n} messages',
  restoreMessage: 'Restore default message',
  messageInfo: 'For your perfect message you may use the following variables:',
  practice_name: 'will substitute with your practice short name',
  meeting_date: 'will substitute with the relevant meeting date',
  meeting_time: 'will substitute with relevant meeting time',
  meeting_room: 'will subscritie with relevant meeting room',
  practice_address: 'will substitue with relevant practice address',
  practice_phone: 'will substitute with your practice (or branch) phone number',
  therapists_phone_number:
    'will substitute with the relevant therapist phone number',
  branch_phone: 'will sustitute with relevant branch phone number',
  branch_address: 'will subscritite with relevant branch address',
  defaultSmsMessage: 'Default SMS message',
  restoreInfo:
    'The default text below cannot be changed but you can tailor it be adding or removing the practice address, phone and name. For each of those you can also add your own data. If you leave it blank it will be populated with the data of your practice.',
  customizeYourMessage: 'Customize your message',
  defaultText: 'Default text',
  searchPlaceholder: 'Search table',
  downloadCsv: 'Download CSV',
  moreFilters: 'Show advanced filters',
  recipient: 'Recipient',
  quota: 'Quota',
  date: 'Date',
  Message: 'Message',
  status: 'Status',
  smsUpdated: 'Sms config successfully updated',
  selectPracticeBranch: 'Select practice branch',
  replies: {
    header: 'Create new quick message',
    name: 'Message name',
    text: 'Message text',
    info: 'Quick messages can be a template if you are often sending the same text to your patients (e.g. payment reminders, holiday reminders, etc.)'
  },
  table: {
    name: 'Name',
    message: 'Message',
    addedOn: 'Added On',
    delete: 'Delete selected',
    empty: 'It seems no messages have been sent in the selected period.',
    emptyDesc: 'Choose a different period'
  },
  day1: '1 day',
  day2: '2 day',
  day3: '3 day',
  smsTemplatePerBranch: 'SMS Template per branch',
  smsTemplatePerBranchDescription:
    'Enable sms template per branch (cannot be disabled)',
  smsTemplatePerBranchTooltip: 'Already enabled, cannnot be disabled',
  restrictedAccess:
    'Invoice for using DobryGabinet has not been paid, smses are turned off',
  sendSMS: 'Send SMS',
  smsTextPlaceholder: 'Type your message ...',
  msgToPatient: 'Message to patient',
  phoneNumbers: 'Phone number(s):',
  maxSmsCountReached: 'You can send up to 10 SMS. Please shorten your message.'
}
