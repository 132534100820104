module.exports = {
  therapistNotificationsEnabled: 'Włączono powiadomienia email dla terapeutów',
  therapistNotificationsDisabled:
    'Powiadomienia email dla terapeutów zostały wyłączone',
  notifyTherapists: 'Powiadamiaj terapeutów wysyłając email',
  confirmRestore: 'Czy na pewno chcesz przywrócić wiadomość domyślną?',
  redirectToSmsPayment: 'Zostaniesz przekierowany na strone z płatnością.',
  packetPurchaseError:
    'Wystąpił błąd po stronie Przelewów24. Skontaktuj się z nimi, lub spróbuj ponownie później.',
  smsPacketBought:
    'Otrzymaliśmy potwierdzenie wpłaty. SMS-y zostały zakupione. Otrzymasz email z potwierdzeniem od Przelewów24.',
  smsPacketAwaitingPayment:
    'Oczekujemy na Twoją płatność. Otrzymasz email ze statusem płatności od Przelewów24.',
  toVerifyMessage:
    'Aby zweryfikować wiadomość, po prostu wpisz swój numer telefonu i wyślij go do siebie.',
  yourPhoneNumber: 'Twój numer telefonu',
  typePhoneNumber: 'Wpisz swój numer telefonu…',
  close: '@:common.close',
  save: '@:common.save',
  ifYourMessageContain:
    'Jeśli wiadomość zawiera datę i godzinę spotkania w tej wiadomości, zostanie ustawiona na dzisiejszą datę.',
  errorMessage: 'Wprowadź prawidłowy numer telefonu (9-18 znaków)',
  defaultText: 'Domyślna wiadomość',
  sendSmsToPatient: "Wyślij SMS'a do pacjenta",
  message: 'Tekst wiadomości',
  messageWillBeSentTo: 'Wiadomość zostanie wysłana do',
  sendTestMsg: 'Wyślij wiadomość testową',
  smsSend: 'SMS został wysłany',
  send: 'Wyślij',
  sms: 'SMS',
  configuration: 'Konfiguracja',
  messages: 'Wiadomości',
  history: 'Historia',
  smsUsage: 'Wykorzystane SMSy',
  sendingTimes: 'Ustawienia wysyłki SMS',
  newMeeting: 'Przypomnienia o nowych spotkaniach',
  smsChangedTime: 'Wysyłka SMS-a po zmianie terminu spotkania',
  smsCancelledTime: 'Wyślij SMS po odwołaniu spotkania',
  smsTimeCancelledInfo:
    'Pacjent otrzyma powiadomieie SMS za każdym razem gdy jego spotkanie zostanie odwołane.',
  buyMoreSms: 'Kup więcej SMS',
  smsInfo: 'Twoi pacjenci otrzymają automatyczne przypomnienia SMS',
  addSmsInfo:
    'Po włączeniu wiadomości SMS terapeuci będą otrzymywać powiadomienie email o nowych i odwołanych spotkaniach.',
  smsSendingInfo:
    'Ustaw kiedy Twoi pacjenci otrzymają przypomnienie SMS o spotkaniu',
  smsNewMeetingInfo:
    'Wysyłaj automatyczne przypomnienie o spotkaniu za każdym razem, gdy nowy pacjent ma zaplanowane nowe spotkanie. Przypomnienie to zostanie wysłane w momencie, w którym spotkanie zostanie dodane do kalendarza.',
  smsTimeChangeInfo:
    'Wysyłaj automatycznie wiadomości SMS o zmianie terminu spotkania po przesunięciu terminu w kalendarzu.',
  haveSms: 'Masz',
  smsLeft: 'wiadomości do wykorzystania do ',
  sendSmsTo: 'Wyślij przypomnienie',
  sendSmsBefore: 'przed spotkaniem o godzinie',
  oClock: '',
  smsPrior: 'przed spotkaniem',
  saveSms: 'Zapisz',
  smsPeriodBefore: 'Ze stałym wyprzedzeniem przed planowanym spotkaniem',
  smsSpecificTime: 'O stałej porze z wyprzedzeniem przed planowym spotkaniem',
  dialogSmsInfo:
    'Zapłacisz {startPrice} zł + VAT ({vat} zł) = {sum} zł, które zostaną doliczone do nowej faktury.',
  smsInvoiceWarn:
    'Zanim przejdziesz do płatności, prosimy upewnić się czy podano prawidłowe dane firmy. Jeśli będą puste, wyślemy do Ciebie fakturę na osobę fizyczną, nie na firmę. Dane znajdują się w zakładce Ustawienia -> Ośrodek / gabinet -> Dane firmy',
  purchaseSmsExplanation:
    'Po kliknięciu, Twoja prośba zostanie do nas wysłana. Fakturę oraz dalsze informacje otrzymasz drogą mailową. Po opłaceniu faktury SMSy zostaną automatycznie doliczone do Twojego konta.',
  buy: 'Kup',
  cancel: 'Anuluj',
  reminderMessage: 'Wiadomość przypominająca',
  sendSpecificMessages: 'Wysyłaj odrębne wiadomości dla każdego oddziału',
  selectBranch: 'Wybierz oddział',
  yourMessage: 'Twoja wiadomość',
  charts: 'znaków',
  messageCount: '{n} wiadomość | {n} wiadomości',
  restoreMessage: 'Przywróć wiadomość domyślną',
  messageInfo: 'W Twojej wiadomości możesz użyć następujących zmiennych:',
  practice_name: 'zastąpi skróconą nazwą twojego gabinetu/ośrodka',
  meeting_date: 'zastąpi właściwą datą spotkania',
  meeting_time: 'zastąpi właściwą godziną spotkania',
  meeting_room: 'zastąpi właściwy numer gabinetu',
  practice_address: 'zastąpi adres gabinetu/ośrodka',
  practice_phone: 'zastąpi numer telefonu do Twojego gabinetu/ośrodka',
  therapists_phone_number: 'zastąpi numer telefonu terapeuty',
  branch_phone: 'zastąpi numer telefonu oddziału',
  branch_address: 'zastąpi adres oddziału',
  defaultSmsMessage: 'Domyślna wiadomość SMS',
  restoreInfo:
    'Domyślnego tekstu poniżej nie można zmienić, ale można go dostosować, dodając lub usuwając: adres, numer telefonu i nazwę gabinetu. Do każdej z tych zmiannych możesz również dopisać własne dane. Jeśli pozostawisz te pola puste, zostaną wypełnione danymi z Twoich ustawień.',
  customizeYourMessage: 'Dostosuj swoją wiadomość',
  searchPlaceholder: 'Szukaj w tabeli',
  downloadCsv: 'Pobierz plik CSV',
  moreFilters: 'Pokaż zaawansowane filtry',
  Message: 'Wiadomość',
  recipient: 'Odbiorca',
  quota: 'Kwota',
  date: 'Data',
  status: 'Status',
  smsUpdated: 'Konfiguracja SMS została pomyślnie zaktualizowana',
  selectPracticeBranch: 'Wybierz oddział',
  replies: {
    header: 'Utwórz nową szybką wiadomość',
    name: 'Nazwa wiadomości',
    text: 'Tekst wiadomości',
    info: 'Szybkie wiadomości mogą być szablonem, jeśli często wysyłasz ten sam tekst do swoich pacjentów (np. przypomnienia o płatnościach, przypomnienia o urlopach itp.'
  },
  table: {
    name: 'Nazwa',
    message: 'Wiadomość',
    addedOn: 'Dodano',
    delete: 'Usuń wybrane',
    empty:
      'Wygląda na to, że w wybranym okresie nie wysłano żadnych wiadomości.',
    emptyDesc: 'Wybierz inny okres'
  },
  reScheduledMeeting: 'Spotkanie przełożone',
  confirmationMessage: 'Wiadomość potwierdzająca',
  meetingCancelled: 'Spotkanie odwołane',
  quickReplies: 'Szybkie powtórki',
  reScheduledInfo:
    'Ta wiadomość zostanie wysłana, gdy zaplanowane spotkanie zostanie przełożone ',
  confirmationInfo:
    'Ta wiadomość potwierdzająca zostanie wysłana do pacjenta po zaplanowaniu jego pierwszego spotkania.',
  canceledInfo:
    'Ta wiadomość zostanie wysłana do Twojego pacjenta, gdy jego spotkanie zostanie odwołane.',
  smsAdditional: 'wiadomości z dodatkowych pakietów',
  smsUsed: 'SMS do użycia.',
  total: 'Razem:',
  smsUsageInfo:
    'Kiedy kupujesz dodatkowe SMS-y, trwają one dłużej niż Twój cykl rozliczeniowy, dopóki nie zostaną zużyte. W pierwszej kolejności system korzysta ze zwykłych SMS-ów, a gdy ich nie będzie, zacznie korzystać z tych dodatkowych.',
  day1: '1 dzień',
  day2: '2 dni',
  day3: '3 dni',
  smsTemplatePerBranch: 'Templatka SMS dla oddziałów',
  smsTemplatePerBranchDescription:
    'Włącz templatkę SMS dla oddziałów (nie można wyłączyć)',
  smsTemplatePerBranchTooltip: 'Włączone, nie można wyłączyć',
  restrictedAccess:
    'Rachunek za korzystanie z Dobrego Gabinetu nie został opłacony, smsy są wyłączone',
  sendSMS: 'Wyślij SMS',
  smsTextPlaceholder: 'Wpisz wiadomość ...',
  msgToPatient: 'Wiadomość do pacjenta',
  phoneNumbers: 'Numer(y) telefonu:',
  maxSmsCountReached: 'Możesz wysłać do 10 SMS-ów. Proszę skrócić wiadomość.'
}
